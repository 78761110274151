<template>
  <div class="auth-wrapper auth-v2">
    <div class="auth-inner">
      <v-row class="auth-row ma-0">
        <v-col lg="8" class="d-none d-lg-block position-relative overflow-hidden pa-0">
          <div class="auth-illustrator-wrapper">
            <!-- triangle bg -->
            <img height="362" class="auth-mask-bg"
              :src="require(`@/assets/images/misc/mask-v2-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)" />

            <!-- 3d character -->
            <div class="d-flex align-center h-full pa-16 pe-0">
              <v-img contain max-width="100%" height="560" class="auth-3d-group"
                :src="require(`@/assets/images/front/front-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"></v-img>
            </div>
          </div>
        </v-col>
        <v-col lg="4" class="d-flex align-center auth-bg pb-0">
          <v-row>
            <v-col cols="12" sm="8" md="6" lg="12" class="mx-auto">
              <v-window v-model="phase">
                <!-- 0 Basicas phase++ -->
                <v-window-item>
                  <v-card flat>
                    <v-card-text>
                      <v-img class="ma-6" max-height="150" max-width="280"
                        src="@/assets/images/svg/cinza - azul.svg"></v-img>
                    </v-card-text>
                    <v-card-text>
                      <v-form v-model="basicPhase" ref="basic">
                        <v-select v-model="plan" :items="plans" :rules="[rules.required]" filled item-text="name"
                          item-value="identifier" label="Plano" outlined>
                        </v-select>
                        <v-text-field v-model="clientForm.name" outlined :rules="[rules.required]" label="Nome Completo"
                          hide-details="auto" class="mb-6"></v-text-field>
                        <v-text-field v-model="clientForm.email" outlined :rules="[rules.required, rules.email]"
                          label="E-Mail" hide-details="auto" class="mb-6"></v-text-field>
                        <v-row>
                          <v-col>
                            <v-text-field v-model="clientForm.cpf" v-mask="'###.###.###-##'" outlined
                              :rules="[rules.required, rules.cpf]" label="CPF" hide-details="auto"
                              class="mb-6"></v-text-field>
                          </v-col>
                          <v-col>
                            <v-text-field v-model="clientForm.phone" v-mask="'(##) # ####-####'" outlined
                              label="Telefone" :rules="[rules.phoneNumber]" hide-details="auto"
                              class="mb-6"></v-text-field>
                          </v-col>
                        </v-row>
                        <v-checkbox v-model="alreadyHasCompany" label="Já tenho uma empresa."></v-checkbox>
                        <v-btn block color="primary" @click="nextStep('basic', phase + 1)"> Próximo </v-btn>
                      </v-form>
                    </v-card-text>
                  </v-card>
                </v-window-item>
                <!-- 1 Endereço phase-- phase = alreadyHasCompany ? 2 : 4 -->
                <v-window-item>
                  <v-card flat>
                    <v-card-text>
                      <p class="mb-2">Informações de Endereço</p>
                    </v-card-text>
                    <address-component @submit="handleClientAddress" ref="address">
                      <v-row>
                        <v-col>
                          <v-btn block @click="phase -= 1"> Retornar </v-btn>
                        </v-col>
                        <v-col>
                          <v-btn block color="primary" @click="nextStep('address', alreadyHasCompany ? 2 : 4)">
                            Próximo
                          </v-btn>
                        </v-col>
                      </v-row>
                    </address-component>
                  </v-card>
                </v-window-item>
                <!-- 2 Info Empresa phase-- phase++ -->
                <v-window-item>
                  <company-form @submit="handleCompany" ref="company" :signup="true">
                    <v-row>
                      <v-col>
                        <v-btn block @click="phase -= 1"> Retornar </v-btn>
                      </v-col>
                      <v-col>
                        <v-btn block color="primary" @click="nextStep('company', phase + 1)"> Próximo </v-btn>
                      </v-col>
                    </v-row>
                  </company-form>
                </v-window-item>
                <!-- 3 Endereço Empresa phase-- phase++ -->
                <v-window-item>
                  <v-card-text>
                    <p class="mb-2">Endereço de sua empresa.</p>
                  </v-card-text>
                  <div>
                    <address-component @submit="handleCompanyAddress" ref="companyAddress">
                      <v-row>
                        <v-col>
                          <v-btn block @click="phase -= 1"> Retornar </v-btn>
                        </v-col>
                        <v-col>
                          <v-btn block color="primary" @click="nextStep('companyAddress', phase + 1)">
                            Próxima
                          </v-btn>
                        </v-col>
                      </v-row>
                    </address-component>
                  </div>
                </v-window-item>
                <!-- 4 Forma de Pagamento phase = alreadyHasCompany ? 1 : 3 -->
                <v-window-item>
                  <v-card flat>
                    <v-card-text>
                      <p class="mb-2">Escolha sua forma de pagamento.</p>
                    </v-card-text>
                    <v-radio-group v-model="payMethod">
                      <v-radio label="Boleto Bancário" :value="'bank_slip'"></v-radio>
                      <v-radio label="Cartão de Crédito" :value="'card'"></v-radio>
                    </v-radio-group>
                    <v-form v-if="payMethod == 'card'" v-model="cardPayMethodPhase" ref="card">
                      <v-card-text>
                        <v-text-field v-model="cardForm.number" outlined label="Numero do Cartão"
                          :rules="[rules.required]" hide-details="auto" class="mb-6"
                          :disabled="creditCardValidated"></v-text-field>
                        <v-text-field v-model="cardForm.name_in_card" outlined label="Titular do Cartão"
                          :rules="[rules.required]" hide-details="auto" class="mb-6"
                          :disabled="creditCardValidated"></v-text-field>
                        <v-row>
                          <v-col>
                            <v-text-field v-model="cardForm.dueDate" v-mask="'##/####'" outlined
                              label="Data de Vencimento" :rules="[rules.required]" hide-details="auto" class="mb-6"
                              :disabled="creditCardValidated"></v-text-field>
                          </v-col>
                          <v-col>
                            <v-text-field v-model="cardForm.cvv" v-mask="'####'" outlined label="CVV"
                              :rules="[rules.required]" hide-details="auto" class="mb-6"
                              :disabled="creditCardValidated"></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row class="pa-3">
                          <v-btn color="success" block @click="handleRequestIugu()">
                            Validar Cartão
                          </v-btn>
                        </v-row>
                      </v-card-text>
                    </v-form>
                    <v-card-text v-else>
                      <p>
                        1 - O boleto bancário será enviado num email para o endereço informado (<i>{{
                          clientForm.email
                          }}</i>). Fique atento na caixa de entrada e caixa de spam.
                      </p>
                      <p>
                        2 - Pagamentos com boleto bancário levam até 3 dias úteis para serem compensados e então terem o
                        acesso liberado.
                      </p>
                      <p>
                        3 - Após o pagamento confirmado, fique atento ao seu e-mail para receber os dados de acesso.
                      </p>
                      <v-btn block :disabled="downloadedBankSlip" @click="() => (downloadedBankSlip = true)"
                        color="success">
                        Enviar Boleto Bancário
                      </v-btn>
                    </v-card-text>
                    <v-card-text>
                      <v-row>
                        <v-col>
                          <v-btn block :disabled="sending" @click="phase = alreadyHasCompany ? 3 : 1"> Retornar </v-btn>
                        </v-col>
                        <v-col>
                          <v-btn block color="primary"
                            :disabled="!((payMethod != 'card' ? downloadedBankSlip : creditCardValidated) && !sending)"
                            @click="submitForm">
                            {{ sending ? 'Enviando...' : 'Inscrever-se' }}
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-window-item>
              </v-window>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <div class="text-center">
      <v-dialog v-model="submitSuccess" width="500" persistent>
        <v-card>
          <v-card-title class="text-h5 grey lighten-2"> Sucesso! </v-card-title>

          <v-card-text>
            Sua inscrição foi enviada sem problemas. Em breve você receberá um e-mail com o contrato de prestação do
            nosso serviço para assinatura. Por favor, leia-o com atenção! Então, logo sua conta do ezcont estará criada
            e disponivel! Obrigado!
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="() => this.$router.push('/login')"> Ótimo! </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <div class="text-center">
      <v-dialog v-model="submitFailure" width="500" persistent>
        <v-card>
          <v-card-title class="text-h5 grey lighten-2"> Oops... </v-card-title>

          <v-card-text>
            Aparentemente, encontramos problemas tecnicos ao enviar ou processar suas informações no servidor. Tente
            novamente mais tarde.
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="() => this.$router.push('/login')"> Entendi. </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <v-snackbar v-model="snackbar">
      ERRO AO CRIAR CONTA NOSSA EQUIPE JÁ ESTA SENDO ALERTADA

      <template v-slot:action="{ attrs }">
        <v-btn color="danger" text v-bind="attrs" @click="snackbar = false"> Fechar </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { OPTIONS_UF } from '@/constants/option'
import addressComponent from '../../components/app/address/addressComponent.vue'
import CompanyForm from '@/components/administration/company/forms/CompanyForm.vue'
import notification from '@/helper/notification'
import formMixin from '@/util/mixins/formMixin'


export default {
  components: { addressComponent, CompanyForm },
  name: 'Signup',

  mixins: [formMixin],

  data() {
    return {
      uf: OPTIONS_UF,

      plans: [],
      plan: null,
      phase: 0,
      sending: false,
      submitSuccess: false,
      submitFailure: false,
      basicPhase: false,
      alreadyHasCompany: true,
      addressPhase: false,
      cardPayMethodPhase: false,
      downloadedBankSlip: false,
      payMethod: 'bank_slip',
      companyPhase: false,
      companyAddressPhase: false,
      creditCardValidated: false,
      cardForm: {
        number: null,
        dueDate: null,
        name_in_card: null,
        cvv: null,
      },
      companyForm: {
        cnpj: null,
        razaoSocial: null,
        nomeFantasia: null,
        inscricaoEstadual: null,
        inscricaoMunicipal: null,
        nfe_integration: null,
        address: {
          cep: null,
          street: null,
          neighborhood: null,
          number: null,
          complement: null,
          state: null,
          city: null,
        },
      },
      clientForm: {
        name: null,
        email: null,
        cpf: null,
        phone: null,
        address: {
          cep: null,
          number: null,
          street: null,
          neighborhood: null,
          complement: null,
          state: null,
          city: null,
        },
      },

      snackbar: false,
      tokenizer: null,
    }
  },

  computed: {
    user() {
      const base = {
        user: this.clientForm,
        plan: this.plan,
        payMethod: this.payMethod,
        tokenCard: this.tokenizer,
      }
      if (this.alreadyHasCompany) {
        base.alreadyHasCompany = this.alreadyHasCompany
        base.company = this.companyForm
      }
      base.payMethod = this.payMethod
      if (base.payMethod === 'card') {
        base.cardInfo = this.cardForm
      }

      return base
    },
  },

  mounted() {
    this.getPlans()

    Iugu.setAccountID('AEB2C0C027011630678D50EFC2D8D9F9')

    Iugu.setTestMode(false)

    Iugu.setup()
  },

  methods: {
    async submitForm() {
      let basicValid = this.$refs.basic.validate()
      let addressValid = this.$refs.address.validate()
      let companyValid = this.alreadyHasCompany ? this.$refs.company.validate() : true
      let companyAddressValid = this.alreadyHasCompany ? this.$refs.companyAddress.validate() : true
      let cardValid = this.payMethod == 'card' ? this.$refs.card.validate() : true
      if (basicValid && addressValid && companyValid && companyAddressValid && cardValid) {
        this.sending = true
        await this.$store
          .dispatch('auth/signup', {
            url: '/signup',
            params: this.user,
          })
          .then(response => {
            this.submitSuccess = true
          })
          .catch(error => {
            console.log(error)
            this.snackbar = true
          })
      }
    },

    handleCompany(e) {
      let address = this.companyForm.address
      this.companyForm = e
      this.companyForm.address = address
    },

    handleCompanyAddress(e) {
      this.companyForm.address = e
    },

    handleClientAddress(e) {
      this.clientForm.address = e
    },

    getPlans() {
      this.$http.index('/plans').then(response => {
        this.plans = response.data.data
      })
    },

    createCreditCard() {
      const number = this.cardForm.number
      const month = this.cardForm.dueDate.substr(0, 2)
      const year = this.cardForm.dueDate.substr(3, 6)
      const titular = this.cardForm.name_in_card
      const cvv = this.cardForm.cvv

      return Iugu.CreditCard(number, month, year, titular.split(' ')[0], titular.split(' ').slice(1).join(' '), cvv)
    },

    cardValidator(cardNumber) {
      let cardNumberValidated = Iugu.utils.validateCreditCardNumber(cardNumber)
      if (!cardNumberValidated) {
        notification.showError('Numero de Cartão de Crédito invalido.')
      }
      return cardNumberValidated
    },

    createCardToken() {
      if (this.cardValidator(this.cardForm.number)) {
        Iugu.createPaymentToken(this.createCreditCard(), response => {
          if (response.errors) {
            console.log(response.errors);
            Object.keys(response.errors).forEach(error => {
              if (error => 'expiration') {
                notification.showError('Data de Expiração invalida.')
              }
              if (error => 'last_name') {
                notification.showError('Sobrenome do titular invalido.')
              }
              if (error => 'first_name') {
                notification.showError('Nome do titular invalido.')
              }
            })
            this.creditCardValidated = false
          } else {
            this.tokenizer = response
            this.creditCardValidated = true
            // notification.showSuccessMsg('Cartão validado; Inscrição autorizada!')
          }
        })
      } else {
        this.creditCardValidated = false
      }
    },

    handleRequestIugu() {
      let cardValid = this.payMethod == 'card' ? this.$refs.card.validate() : true
      if (cardValid) {
        this.createCardToken()
      }
    },

    nextStep(valid, value) {
      if (this.$refs[valid].validate()) {
        this.phase = value
      }
    }

  },
}
</script>
<style lang="scss" scoped>
@import '@core/preset/preset/pages/auth.scss';

#logo {
  position: absolute;
  bottom: 0;
  left: 0;
}
</style>
